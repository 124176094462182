<template>
  <div>
    <b-navbar toggleable="sm" variant="primary" type="dark">
      <b-navbar-brand :to="{ name: 'home' }">Dot'n'Line</b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item :to="{ name: 'about' }" active-class="active"
            >Services</b-nav-item
          >
          <b-nav-item :to="{ name: 'about' }" active-class="active"
            >About</b-nav-item
          >
        </b-navbar-nav>

        <b-navbar-nav class="ml-auto">
          <router-link class="btn btn-sm btn-success my-2 my-sm-0" :to="{ name: 'home' }">
            Get a Quote
          </router-link>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<style lang="scss">
.navbar {
  font-family: "Carter One", cursive;
}
</style>

<script>
export default {
  name: "NavBar",
};
</script>
