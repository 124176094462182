<template>
  <div id="app">
    <NavBar />
    <router-view/>
  </div>
</template>

<style lang="scss">
@import "~@/assets/scss/vendors/bootstrap-vue/index";
@import url('https://fonts.googleapis.com/css2?family=Balsamiq+Sans&family=Carter+One&display=swap');

#app {
  font-family: 'Balsamiq Sans', cursive;
}
</style>

<script>
import NavBar from "@/includes/NavBar.vue";

export default {
  name: 'Home',
  components: {
    NavBar,
  }
}
</script>

